export default [
  {
    path: '/navigations',
    name: 'navigations-index',
    component: () => import('@/views/navigations/List.vue'),
    meta: {
      pageTitle: 'administration.navigation.navigations',
      breadcrumb: [
        {
          text: 'administration.navigation.navigations',
          active: true,
        },
      ],
      resource: 'navigations',
      action: 'index',
    },
  },
  {
    path: '/navigations/create',
    name: 'navigations-store',
    component: () => import('@/views/navigations/Create.vue'),
    meta: {
      pageTitle: 'administration.navigation.navigations',
      breadcrumb: [
        {
          text: 'administration.navigation.navigations',
          to: { name: 'navigations-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'navigations',
      action: 'store',
    },
  },
  {
    path: '/navigations/edit/:id',
    name: 'navigations-update',
    component: () => import('@/views/navigations/Edit.vue'),
    meta: {
      pageTitle: 'administration.navigation.navigations',
      breadcrumb: [
        {
          text: 'administration.navigation.navigations',
          to: { name: 'navigations-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'navigations',
      action: 'update',
    },
  },
  {
    path: '/navigation-items/:navigation_id/create',
    name: 'navigation-items-store',
    component: () => import('@/views/navigations/items/Create.vue'),
    meta: {
      pageTitle: 'administration.navigations.tabs.items.label',
      breadcrumb: [
        {
          text: 'administration.navigations.tabs.items.label',
          to: { name: 'navigations-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'navigations',
      action: 'store',
    },
  },
  {
    path: '/navigation-items/:navigation_id/edit/:id',
    name: 'navigation-items-update',
    component: () => import('@/views/navigations/items/Edit.vue'),
    meta: {
      pageTitle: 'administration.navigations.tabs.items.label',
      breadcrumb: [
        {
          text: 'administration.navigations.tabs.items.label',
          to: { name: 'navigations-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'navigations',
      action: 'update',
    },
  },
]
