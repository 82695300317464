<template>
  <component :is="'b-card'">
    <b-tabs pills>

      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('administration.general.title') }}</span>
        </template>
        <GeneralTab class="mt-2 pt-75" />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('administration.delivery.title') }}</span>
        </template>
        <DeliveryTab class="mt-2 pt-75" />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('administration.localization.title') }}</span>
        </template>
        <LocalizationTab class="mt-2 pt-75" />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('administration.nova_poshta.title') }}</span>
        </template>
        <NovaPoshtaTab class="mt-2 pt-75" />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('administration.account_setting.sms.title') }}</span>
        </template>
        <SmsFlyTab class="mt-2 pt-75" />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('administration.account_setting.wayforpay.title') }}</span>
        </template>
        <WayTorPayTab class="mt-2 pt-75" />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('administration.contacts.title') }}</span>
        </template>
        <ContactTab class="mt-2 pt-75" />
      </b-tab>

    </b-tabs>
  </component>
</template>

<script>
import {
  BCard, BTab, BTabs,
} from 'bootstrap-vue'
import LocalizationTab from './LocalizationTab.vue'
import NovaPoshtaTab from './NovaPoshtaTab.vue'
import SmsFlyTab from './SmsFlyTab.vue'
import GeneralTab from './GeneralTab.vue'
import ContactTab from './ContactTab.vue'
import DeliveryTab from './DeliveryTab.vue'
import WayTorPayTab from './WayTorPayTab.vue'

export default {
  components: {
    BCard,
    BTab,
    BTabs,
    LocalizationTab,
    NovaPoshtaTab,
    SmsFlyTab,
    GeneralTab,
    ContactTab,
    DeliveryTab,
    WayTorPayTab,
  },
}
</script>
