export default [
  {
    path: '/account-setting',
    name: 'account-setting',
    component: () => import('@/views/account-setting/AccountSetting.vue'),
    meta: {
      pageTitle: 'administration.navigation.settings',
      breadcrumb: [
        {
          text: 'administration.navigation.system',
        },
        {
          text: 'administration.navigation.settings',
          active: true,
        },
      ],
      resource: 'account',
      action: 'index',
    },
  },
]
