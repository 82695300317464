<template>
  <b-overlay
    :show="data === null"
    rounded="sm"
  >
    <b-form
      v-if="data"
      class="mt-1"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="$t('form.login.label')"
            label-for="wayforpay_account"
          >
            <b-form-input
              id="wayforpay_account"
              v-model="data.wayforpay_account"
              :state="errors && errors.wayforpay_account ? false : null"
            />

            <b-form-invalid-feedback v-if="errors && errors.wayforpay_account">
              {{ errors.wayforpay_account[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="$t('form.password.label')"
            label-for="wayforpay_secret"
          >
            <b-form-input
              id="wayforpay_secret"
              v-model="data.wayforpay_secret"
              :state="errors && errors.wayforpay_secret ? false : null"
            />

            <b-form-invalid-feedback v-if="errors && errors.wayforpay_secret">
              {{ errors.wayforpay_secret[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="$ability.can('update', 'settings')"
        class="mt-2"
      >
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="submit"
          >
            {{ $t('general.save') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-overlay>
</template>

<script>
import {
  BRow, BCol, BForm, BButton, BOverlay, BFormGroup, BFormInvalidFeedback, BFormInput,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BRow, BCol, BForm, BButton, BOverlay, BFormGroup, BFormInvalidFeedback, BFormInput,
  },
  data() {
    return {
      data: null,
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http('/api/administration/settings', { params: { group: 'wayforpay' } }).then(response => {
      const { data: { wayforpay_account, wayforpay_secret } } = response

      this.data = {
        wayforpay_account,
        wayforpay_secret,
      }
    })
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    async submit() {
      await this.$http.put('/api/administration/settings/wayforpay', this.data)
        .then(async () => {
          this.$store.dispatch('validation/clearErrors')

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              text: this.$t('notifications.success.updated'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
    },
  },
}
</script>
