export default [
  {
    path: '/messages',
    name: 'messages-index',
    component: () => import('@/views/messages/List.vue'),
    meta: {
      pageTitle: 'administration.navigation.messages',
      breadcrumb: [
        {
          text: 'administration.navigation.messages',
          active: true,
        },
      ],
      resource: 'messages',
      action: 'index',
    },
  },
  {
    path: '/messages/show/:id',
    name: 'messages-show',
    component: () => import('@/views/messages/Show.vue'),
    meta: {
      pageTitle: 'administration.navigation.messages',
      breadcrumb: [
        {
          text: 'administration.navigation.messages',
          to: { name: 'messages-index' },
        },
        {
          text: 'general.view',
          active: true,
        },
      ],
      resource: 'messages',
      action: 'show',
    },
  },
]
