<template>
  <b-row>
    <b-col
      cols="12"
      md="6"
    >
      <b-card :title="$t('administration.navigation.access_control')">
        <b-card-text>{{ $t('administration.access_control') }}</b-card-text>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BRow, BCol, BCard, BCardText,
  },
}
</script>

<style>

</style>
