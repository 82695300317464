<template>
  <component :is="collection === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="collection === null"
      rounded="sm"
    >
      <div
        v-if="collection"
        class="mt-2 pt-75"
      >
        <b-form>
          <b-row>
            <b-tabs>
              <b-tab
                v-for="language in languages"
                :key="language.code"
              >
                <template #title>
                  <b-img
                    :src="require('@/assets/images/flags/' + language.code + '.svg')"
                    height="16"
                    width="16"
                    class="mr-1"
                  />
                  <span class="d-none d-sm-inline">{{ language.title }}</span>
                </template>

                <div class="repeater-form">
                  <b-row
                    :id="'item' + language.code"
                    ref="row"
                  >

                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.meta_title.label')"
                        :label-for="'meta_title.' + language.code"
                      >
                        <b-form-input
                          :id="'meta_title.' + language.code"
                          v-model="collection.meta_title[language.code]"
                          :state="errors && errors['meta_title.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['meta_title.' + language.code]">
                          {{ errors['meta_title.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.meta_description.label')"
                        :label-for="'meta_description.' + language.code"
                      >
                        <b-form-textarea
                          :id="'meta_description.' + language.code"
                          v-model="collection.meta_description[language.code]"
                          :state="errors && errors['meta_description.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['meta_description.' + language.code]">
                          {{ errors['meta_description.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <hr>
                    </b-col>
                  </b-row>
                </div>
              </b-tab>
            </b-tabs>
          </b-row>
          <b-row>
            <div class="repeater-form">
              <b-row
                v-for="(item, index) in collection.extensions"
                :id="'item' + index"
                :key="index"
                ref="row"
              >
                <b-col
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.extension_id.label')"
                    :label-for="'extensions.' + index + '.extension_id'"
                    :state="errors && errors['extensions.' + index + '.extension_id'] ? false : null"
                  >
                    <v-select
                      :id="'extensions.' + index + '.extension_id'"
                      v-model="item.extension_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="extensionOptions"
                      :reduce="val => val.value"
                      :input-id="'extensions.' + index + '.extension_id'"
                      :searchable="true"
                      @search="searchExtension"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['extensions.' + index + '.extension_id']">
                      {{ errors['extensions.' + index + '.extension_id'][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.position.label')"
                    :label-for="'extensions.' + index + '.position'"
                  >
                    <b-form-input
                      :id="'extensions.' + index + '.position'"
                      v-model="item.position"
                      :state="errors && errors['extensions.' + index + '.position'] ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors['extensions.' + index + '.position']">
                      {{ errors['extensions.' + index + '.position'][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <!-- Remove Button -->
                <b-col
                  lg="2"
                  md="3"
                  class="mb-50"
                >
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    @click="remove(index)"
                  >
                    <feather-icon
                      icon="XIcon"
                      class="mr-25"
                    />
                    <span>{{ $t('general.delete') }}</span>
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </div>
          </b-row>
          <b-row
            v-if="errors && errors.extensions"
          >
            <b-col cols="12">
              <b-form-invalid-feedback :state="false">
                {{ errors.extensions[0] }}
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-row>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="add"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>{{ $t('general.add') }}</span>
            </b-button>
          </b-row>
          <b-row
            v-if="$ability.can('update', 'settings')"
            class="mt-2"
          >
            <b-col>
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="submit"
              >
                {{ $t('general.save') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BButton,
  BOverlay,
  BFormGroup,
  BFormInvalidFeedback,
  BFormInput,
  BTab,
  BTabs,
  BImg,
  BFormTextarea,
  BCard,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Ripple from 'vue-ripple-directive'
import _ from 'lodash'
import vSelect from 'vue-select'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCard,
    BCol,
    BForm,
    BButton,
    BOverlay,
    BFormGroup,
    BFormInvalidFeedback,
    BFormInput,
    BTab,
    BTabs,
    BImg,
    BFormTextarea,
    vSelect,
  },
  data() {
    return {
      collection: null,
      languages: [],
      extensionOptions: [],
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    await this.$http('/api/administration/home').then(response => {
      const { data } = response.data

      if (!data || !Object.keys(data).length) {
        const value = {
          meta_description: {},
          meta_title: {},
          extensions: [],
        }

        _.each(this.languages, language => {
          value.meta_description[language.code] = null
          value.meta_title[language.code] = null
        })

        this.collection = value
      } else {
        this.collection = this.transform(data)
      }
    })
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    transform(data) {
      const value = {
        id: data.id,
        meta_description: {},
        meta_title: {},
      }

      _.each(this.languages, language => {
        const translation = _.find(data.translations, { locale: language.code })

        value.meta_description[language.code] = translation ? translation.meta_description : null
        value.meta_title[language.code] = translation ? translation.meta_title : null
      })

      value.extensions = _.reduce(data.extensions, (result, extension) => {
        if (!_.find(this.extensionOptions, { value: extension.id })) {
          this.extensionOptions.push({
            value: extension.id,
            label: extension.title,
          })
        }

        result.push({
          id: extension.id,
          extension_id: extension.id,
          position: extension.position,
        })
        return result
      }, [])

      return value
    },
    async searchExtension(query) {
      await this.$http.get('/api/administration/extensions', {
        params: {
          query,
        },
      })
        .then(response => {
          this.extensionOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    add() {
      this.collection.extensions.push({
        position: Object.keys(this.collection.extensions).length + 1,
        extension_id: null,
      })
    },
    remove(index) {
      this.collection.extensions.splice(index, 1)
    },
    async submit() {
      await this.$http.post('/api/administration/home', this.collection)
        .then(async () => {
          this.$store.dispatch('validation/clearErrors')

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              text: this.$t('notifications.success.updated'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
