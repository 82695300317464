export default [
  {
    header: 'administration.navigation.pages_list',
  },
  {
    title: 'administration.navigation.pages',
    icon: 'ColumnsIcon',
    children: [
      {
        title: 'administration.home.title',
        route: { name: 'pages-home-update' },
        action: 'update',
        resource: 'pages',
      },
      {
        title: 'administration.catalog.title',
        route: { name: 'pages-catalog-update' },
        action: 'update',
        resource: 'pages',
      },
      {
        title: 'administration.navigation.sale',
        route: { name: 'pages-sale-update' },
        action: 'update',
        resource: 'pages',
      },
      {
        title: 'administration.navigation.novelty',
        route: { name: 'pages-novelty-update' },
        action: 'update',
        resource: 'pages',
      },
      // {
      //   title: 'administration.navigation.coming_soon',
      //   route: { name: 'pages-coming_soon-update' },
      //   action: 'update',
      //   resource: 'pages',
      // },
      {
        title: 'administration.navigation.pages',
        route: 'pages-index',
        action: 'index',
        resource: 'pages',
      },
    ],
  },
]
