export default [
  {
    path: '/product-categories',
    name: 'product-categories-index',
    component: () => import('@/views/categories/product/List.vue'),
    meta: {
      pageTitle: 'administration.navigation.categories',
      breadcrumb: [
        {
          text: 'administration.navigation.categories',
          active: true,
        },
      ],
      resource: 'categories',
      action: 'index',
    },
  },
  {
    path: '/product-categories/create',
    name: 'product-categories-store',
    component: () => import('@/views/categories/product/Create.vue'),
    meta: {
      pageTitle: 'administration.navigation.categories',
      breadcrumb: [
        {
          text: 'administration.navigation.categories',
          to: { name: 'product-categories-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'categories',
      action: 'store',
    },
  },
  {
    path: '/product-categories/edit/:id',
    name: 'product-categories-update',
    component: () => import('@/views/categories/product/Edit.vue'),
    meta: {
      pageTitle: 'administration.navigation.categories',
      breadcrumb: [
        {
          text: 'administration.navigation.categories',
          to: { name: 'product-categories-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'categories',
      action: 'update',
    },
  },

  {
    path: '/post-categories',
    name: 'post-categories-index',
    component: () => import('@/views/categories/post/List.vue'),
    meta: {
      pageTitle: 'administration.navigation.categories',
      breadcrumb: [
        {
          text: 'administration.navigation.categories',
          active: true,
        },
      ],
      resource: 'categories',
      action: 'index',
    },
  },
  {
    path: '/post-categories/create',
    name: 'post-categories-store',
    component: () => import('@/views/categories/post/Create.vue'),
    meta: {
      pageTitle: 'administration.navigation.categories',
      breadcrumb: [
        {
          text: 'administration.navigation.categories',
          to: { name: 'post-categories-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'categories',
      action: 'store',
    },
  },
  {
    path: '/post-categories/edit/:id',
    name: 'post-categories-update',
    component: () => import('@/views/categories/post/Edit.vue'),
    meta: {
      pageTitle: 'administration.navigation.categories',
      breadcrumb: [
        {
          text: 'administration.navigation.categories',
          to: { name: 'post-categories-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'categories',
      action: 'update',
    },
  },
]
