export default [
  {
    header: 'administration.navigation.users_list',
  },
  {
    title: 'administration.navigation.users',
    icon: 'UserIcon',
    children: [
      {
        title: 'administration.navigation.users_list',
        route: 'users-index',
        action: 'index',
        resource: 'users',
      },
      {
        title: 'administration.navigation.roles',
        route: 'roles-index',
        action: 'index',
        resource: 'roles',
      },
    ],
  },
]
