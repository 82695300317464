export default [
  {
    header: 'administration.navigation.seo_pages',
  },
  {
    title: 'administration.navigation.seo',
    icon: 'AnchorIcon',
    children: [

      {
        title: 'administration.navigation.seo_pages',
        route: 'seo_pages-index',
        action: 'index',
        resource: 'seo_pages',
      },
      {
        title: 'administration.navigation.seo_templates',
        route: 'seo_templates-index',
        action: 'index',
        resource: 'seo',
      },
    ],
  },
]
