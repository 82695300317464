<template>
  <b-overlay
    :show="data === null"
    rounded="sm"
  >
    <b-form
      v-if="data"
      class="mt-1"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="$t('administration.localization.language.title')"
            label-for="language"
          >
            <v-select
              v-model="data.language"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="languages"
              :clearable="false"
              input-id="language"
            />
            <b-form-invalid-feedback v-if="errors && errors.language">
              {{ errors.language[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="$t('administration.localization.admin_language.title')"
            label-for="admin_language"
          >
            <v-select
              v-model="data.admin_language"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="languages"
              :clearable="false"
              input-id="admin_language"
            />
            <b-form-invalid-feedback v-if="errors && errors.admin_language">
              {{ errors.admin_language[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

      </b-row>

      <b-row
        v-if="$ability.can('update', 'settings')"
        class="mt-2"
      >
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="submit"
          >
            {{ $t('general.save') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-overlay>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BButton, BFormInvalidFeedback, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, vSelect, BButton, BFormInvalidFeedback, BOverlay,
  },
  data() {
    return {
      languages: [],
      data: null,
      language: null,
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http('/api/languages')
      .then(response => {
        this.languages = this.$options.filters.transformForVSelect(response.data.data, 'code', 'title')
      })
    await this.$http('/api/administration/settings', { params: { group: 'localization' } }).then(response => {
      const { data: { language, admin_language } } = response

      /* eslint-disable camelcase */
      this.language = admin_language

      /* eslint-disable camelcase */
      this.data = {
        language: {
          label: _.find(this.languages, { value: language }).label,
          value: language,
        },
        admin_language: {
          label: _.find(this.languages, { value: admin_language }).label,
          value: admin_language,
        },
      }
    })
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    async submit() {
      await this.$http.put('/api/administration/settings/localization', {
        language: this.data.language.value,
        admin_language: this.data.admin_language.value,
      })
        .then(async () => {
          await localStorage.setItem('localization', JSON.stringify({
            admin_language: this.data.admin_language.value,
            language: this.data.language.value,
          }))

          await this.changeLanguage()

          this.$store.dispatch('validation/clearErrors')

          if (this.language !== this.data.admin_language.value) {
            window.location.reload()
          }

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              text: this.$t('notifications.success.updated'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
    },
    changeLanguage() {
      this.$i18n.locale = this.data.admin_language.value
      this.$i18n.fallbackLocale = this.data.language.value
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
