export default [
  {
    path: '/posts',
    name: 'posts-index',
    component: () => import('@/views/posts/List.vue'),
    meta: {
      pageTitle: 'administration.navigation.posts',
      breadcrumb: [
        {
          text: 'administration.navigation.posts',
          active: true,
        },
      ],
      resource: 'posts',
      action: 'index',
    },
  },
  {
    path: '/posts/create',
    name: 'posts-store',
    component: () => import('@/views/posts/Create.vue'),
    meta: {
      pageTitle: 'administration.navigation.posts',
      breadcrumb: [
        {
          text: 'administration.navigation.posts',
          to: { name: 'posts-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'posts',
      action: 'store',
    },
  },
  {
    path: '/posts/edit/:id',
    name: 'posts-update',
    component: () => import('@/views/posts/Edit.vue'),
    meta: {
      pageTitle: 'administration.navigation.posts',
      breadcrumb: [
        {
          text: 'administration.navigation.posts',
          to: { name: 'posts-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'posts',
      action: 'update',
    },
  },
]
