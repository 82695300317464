export default [
  {
    header: 'administration.navigation.post_list',
  },
  {
    title: 'administration.navigation.posts',
    icon: 'PaperclipIcon',
    children: [
      {
        title: 'administration.navigation.categories',
        route: { name: 'post-categories-index' },
        action: 'index',
        resource: 'categories',
      },
      {
        title: 'administration.navigation.post_list',
        route: 'posts-index',
        action: 'index',
        resource: 'posts',
      },
    ],
  },
]
