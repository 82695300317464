<template>
  <b-overlay
    :show="data === null"
    rounded="sm"
  >
    <b-form
      v-if="data"
      class="mt-1"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="$t('form.login.label')"
            label-for="login"
          >
            <b-form-input
              id="login"
              v-model="data.login"
              :state="errors && errors.login ? false : null"
            />

            <b-form-invalid-feedback v-if="errors && errors.login">
              {{ errors.login[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="$t('form.password.label')"
            label-for="password"
          >
            <b-form-input
              id="password"
              v-model="data.password"
              :state="errors && errors.password ? false : null"
            />

            <b-form-invalid-feedback v-if="errors && errors.password">
              {{ errors.password[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="$t('form.from.label')"
            label-for="from"
          >
            <b-form-input
              id="from"
              v-model="data.from"
              :state="errors && errors.from ? false : null"
            />

            <b-form-invalid-feedback v-if="errors && errors.from">
              {{ errors.from[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="$ability.can('update', 'settings')"
        class="mt-2"
      >
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="submit"
          >
            {{ $t('general.save') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-overlay>
</template>

<script>
import {
  BRow, BCol, BForm, BButton, BOverlay, BFormGroup, BFormInvalidFeedback, BFormInput,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BRow, BCol, BForm, BButton, BOverlay, BFormGroup, BFormInvalidFeedback, BFormInput,
  },
  data() {
    return {
      data: null,
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http('/api/administration/settings', { params: { group: 'sms' } }).then(response => {
      const { data: { login, password, from } } = response

      this.data = {
        login,
        password,
        from,
      }
    })
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    async submit() {
      await this.$http.put('/api/administration/settings/sms', this.data)
        .then(async () => {
          this.$store.dispatch('validation/clearErrors')

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              text: this.$t('notifications.success.updated'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
    },
  },
}
</script>
