export default [
  {
    header: 'Apps & Pages',
  },
  {
    title: 'Pages',
    icon: 'FileIcon',
    children: [
      {
        title: 'Blog',
        children: [
          {
            title: 'List',
            route: 'blog-list',
          },
          {
            title: 'Detail',
            route: { name: 'blog-detail', params: { id: 1 } },
          },
          {
            title: 'Edit',
            route: { name: 'blog-edit', params: { id: 1 } },
          },
        ],
      },
      {
        title: 'Mail Templates',
        children: [
          {
            title: 'Welcome',
            href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-welcome.html',
          },
          {
            title: 'Reset Password',
            href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-reset-password.html',
          },
          {
            title: 'Verify Email',
            href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-verify-email.html',
          },
          {
            title: 'Deactivate Account',
            href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-deactivate-account.html',
          },
          {
            title: 'Invoice',
            href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-invoice.html',
          },
          {
            title: 'Promotional',
            href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-promotional.html',
          },
        ],
      },
    ],
  },
]
