export default [
  {
    header: 'administration.navigation.products',
  },
  {
    title: 'administration.navigation.catalog',
    icon: 'ArchiveIcon',
    children: [
      {
        title: 'administration.navigation.categories',
        route: { name: 'product-categories-index' },
        action: 'index',
        resource: 'categories',
      },
      {
        title: 'administration.navigation.products',
        route: 'products-index',
        action: 'index',
        resource: 'products',
      },
      {
        title: 'administration.navigation.stickers',
        route: 'stickers-index',
        action: 'index',
        resource: 'stickers',
      },
      {
        title: 'administration.navigation.stocks',
        route: 'stocks-index',
        action: 'index',
        resource: 'stocks',
      },
      {
        title: 'administration.navigation.attributes',
        route: 'attributes-index',
        action: 'index',
        resource: 'attributes',
      },
      {
        title: 'administration.navigation.sales',
        route: 'sales-index',
        action: 'index',
        resource: 'sales',
      },
    ],
  },
]
