export default [
  // Invoice
  {
    path: '/invoice/list',
    name: 'invoice-list',
    component: () => import('@/views/invoice/invoice-list/InvoiceList.vue'),
  },
  {
    path: '/invoice/preview/:id',
    name: 'invoice-preview',
    component: () => import('@/views/invoice/invoice-preview/InvoicePreview.vue'),
  },
  {
    path: '/invoice/add/',
    name: 'invoice-add',
    component: () => import('@/views/invoice/invoice-add/InvoiceAdd.vue'),
  },
  {
    path: '/invoice/edit/:id',
    name: 'invoice-edit',
    component: () => import('@/views/invoice/invoice-edit/InvoiceEdit.vue'),
  },
]
