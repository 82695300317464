<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div
        v-if="item"
        class="mt-2 pt-75"
      >
        <b-form>
          <b-tabs pills>
            <b-tab :title="$t('administration.coupons.tabs.translate.label')">
              <b-tabs>
                <b-tab
                  v-for="language in languages"
                  :key="language.code"
                >
                  <template #title>
                    <b-img
                      :src="require('@/assets/images/flags/' + language.code + '.svg')"
                      height="16"
                      width="16"
                      class="mr-1"
                    />
                    <span class="d-none d-sm-inline">{{ language.title }}</span>
                  </template>
                  <b-row>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.title.label')"
                        :label-for="'title.' + language.code"
                      >
                        <b-form-input
                          :id="'title.' + language.code"
                          v-model="item.title[language.code]"
                          :state="errors && errors['title.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                          {{ errors['title.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </b-tab>
            <b-tab :title="$t('administration.coupons.tabs.general.label')">
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.code.label')"
                    label-for="code"
                  >
                    <b-form-input
                      id="code"
                      v-model="item.code"
                      :state="errors && errors.code ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.code">
                      {{ errors.code[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.status.label')"
                    label-for="status"
                    :state="errors && errors.status ? false : null"
                  >
                    <v-select
                      id="status"
                      v-model="item.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="status"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.status">
                      {{ errors.status[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.type.label')"
                    label-for="type"
                    :state="errors && errors.type ? false : null"
                  >
                    <v-select
                      id="type"
                      v-model="item.type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="typeOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="type"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.type">
                      {{ errors.type[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.value.label')"
                    label-for="value"
                  >
                    <b-form-input
                      id="value"
                      v-model="item.value"
                      type="number"
                      :state="errors && errors.value ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.value">
                      {{ errors.value[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.free_shipping.label')"
                    label-for="free_shipping"
                  >
                    <b-form-checkbox
                      id="free_shipping"
                      v-model="item.free_shipping"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.free_shipping">
                      {{ errors.free_shipping[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.started_at.label')"
                    label-for="started_at"
                    :state="errors && errors.started_at ? false : null"
                  >
                    <b-form-datepicker
                      id="started_at"
                      v-model="item.started_at"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.started_at">
                      {{ errors.started_at[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.ended_at.label')"
                    label-for="ended_at"
                    :state="errors && errors.ended_at ? false : null"
                  >
                    <b-form-datepicker
                      id="ended_at"
                      v-model="item.ended_at"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.ended_at">
                      {{ errors.ended_at[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              :title="$t('administration.coupons.tabs.data.label')"
              @click="loadDataTab"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.minimum_spend.label')"
                    label-for="minimum_spend"
                  >
                    <b-form-input
                      id="minimum_spend"
                      v-model="item.minimum_spend"
                      type="number"
                      :state="errors && errors.minimum_spend ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.minimum_spend">
                      {{ errors.minimum_spend[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.maximum_spend.label')"
                    label-for="maximum_spend"
                  >
                    <b-form-input
                      id="maximum_spend"
                      v-model="item.maximum_spend"
                      type="number"
                      :state="errors && errors.maximum_spend ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.maximum_spend">
                      {{ errors.maximum_spend[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.categories.label')"
                    label-for="categories"
                    :state="errors && errors.categories ? false : null"
                  >
                    <treeselect
                      id="categories"
                      v-model="item.categories"
                      :multiple="true"
                      :options="categoryOptions"
                      :normalizer="normalizer"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.categories">
                      {{ errors.categories[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.exclude_categories.label')"
                    label-for="exclude_categories"
                    :state="errors && errors.exclude_categories ? false : null"
                  >
                    <treeselect
                      id="exclude_categories"
                      v-model="item.exclude_categories"
                      :multiple="true"
                      :options="categoryOptions"
                      :normalizer="normalizer"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.exclude_categories">
                      {{ errors.exclude_categories[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.products.label')"
                    label-for="products"
                    :state="errors && errors.products ? false : null"
                  >
                    <v-select
                      id="products"
                      v-model="item.products"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="productOptions"
                      :reduce="val => val.value"
                      :taggable="true"
                      :searchable="true"
                      :multiple="true"
                      input-id="products"
                      @search="searchProduct"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.products">
                      {{ errors.products[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.exclude_products.label')"
                    label-for="exclude_products"
                    :state="errors && errors.exclude_products ? false : null"
                  >
                    <v-select
                      id="exclude_products"
                      v-model="item.exclude_products"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="excludeProductOptions"
                      :reduce="val => val.value"
                      :taggable="true"
                      :searchable="true"
                      :multiple="true"
                      input-id="exclude_products"
                      @search="searchExcludeProduct"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.exclude_products">
                      {{ errors.exclude_products[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.usage_limit_per_coupon.label')"
                    label-for="usage_limit_per_coupon"
                  >
                    <b-form-input
                      id="usage_limit_per_coupon"
                      v-model="item.usage_limit_per_coupon"
                      type="number"
                      :state="errors && errors.usage_limit_per_coupon ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.usage_limit_per_coupon">
                      {{ errors.usage_limit_per_coupon[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.usage_limit_per_customer.label')"
                    label-for="usage_limit_per_customer"
                  >
                    <b-form-input
                      id="usage_limit_per_customer"
                      v-model="item.usage_limit_per_customer"
                      type="number"
                      :state="errors && errors.usage_limit_per_customer ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.usage_limit_per_customer">
                      {{ errors.usage_limit_per_customer[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'coupons-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BButton,
  BRow,
  BCol,
  BMedia,
  BMediaBody,
  BMediaAside,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BFormInvalidFeedback,
  BImg,
  BOverlay,
  BFormTextarea,
  BLink,
  BFormCheckbox,
  BFormDatepicker,
} from 'bootstrap-vue'
import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import StatusMixin from '@/mixins/StatusMixin'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import CategoryMixin from '@/mixins/CategoryMixin'

export default {
  directives: {
    Ripple,
  },
  components: {
    BTab,
    BTabs,
    BCard,
    BMedia,
    BMediaBody,
    BMediaAside,
    BLink,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BImg,
    BFormTextarea,
    BFormInvalidFeedback,
    vSelect,
    BOverlay,
    Treeselect,
    BFormCheckbox,
    BFormDatepicker,
  },
  mixins: [StatusMixin, CategoryMixin],
  data() {
    return {
      item: null,
      languages: [],
      categoryOptions: [],
      productOptions: [],
      excludeProductOptions: [],
      typeOptions: [
        { label: this.$t('general.coupon_types.percent'), value: 'percent' },
        { label: this.$t('general.coupon_types.fixed'), value: 'fixed' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    await this.$http.get(`/api/administration/coupons/${router.currentRoute.params.id}`)
      .then(response => {
        const { data } = response.data

        this.item = this.transformData(data)

        this.productOptions = this.$options.filters.transformForVSelect(data.products, 'id', 'title')
        this.excludeProductOptions = this.$options.filters.transformForVSelect(data.excludeProducts, 'id', 'title')
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.item = undefined
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'coupons-index' })
        }
      })
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    async searchProduct(query) {
      await this.$http.get('/api/administration/products', {
        params: {
          query,
        },
      })
        .then(response => {
          this.productOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    async searchExcludeProduct(query) {
      await this.$http.get('/api/administration/products', {
        params: {
          query,
        },
      })
        .then(response => {
          this.excludeProductOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    async loadDataTab() {
      if (!Object.keys(this.categoryOptions).length) {
        await this.$http.get('/api/administration/categories', {
          params: {
            type: 'product',
            status: 'enabled',
          },
        })
          .then(response => {
            this.categoryOptions = response.data
          })
      }
    },
    onSubmit() {
      const data = {
        title: this.item.title,
        status: this.item.status,
        type: this.item.type,
        code: this.item.code,
        value: this.item.value,
        free_shipping: this.item.free_shipping,
        minimum_spend: this.item.minimum_spend,
        maximum_spend: this.item.maximum_spend,
        usage_limit_per_coupon: this.item.usage_limit_per_coupon,
        usage_limit_per_customer: this.item.usage_limit_per_customer,
        used: this.item.used,
        started_at: this.item.started_at,
        ended_at: this.item.ended_at,
        categories: this.item.categories,
        exclude_categories: this.item.exclude_categories,
        products: this.item.products,
        exclude_products: this.item.exclude_products,
      }

      this.$http.put(`/api/administration/coupons/${this.item.id}`, data)
        .then(() => {
          router.replace({ name: 'coupons-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          this.$refs.roleForm.setErrors(error.response.data.errors)
        })
    },
    transformData(item) {
      const data = {
        id: item.id,
        status: item.status,
        type: item.type,
        code: item.code,
        value: item.value,
        free_shipping: item.free_shipping,
        minimum_spend: item.minimum_spend,
        maximum_spend: item.maximum_spend,
        usage_limit_per_coupon: item.usage_limit_per_coupon,
        usage_limit_per_customer: item.usage_limit_per_customer,
        used: item.used,
        started_at: item.started_at,
        ended_at: item.ended_at,
        categories: item.categories,
        exclude_categories: item.excludeCategories,
        products: item.products,
        exclude_products: item.excludeProducts,
        title: {},
      }

      _.each(this.languages, language => {
        const translation = _.find(item.translations, { locale: language.code })

        data.title[language.code] = translation ? translation.title : null
      })

      data.categories = _.map(data.categories, 'id')
      data.products = _.map(data.products, 'id')

      data.exclude_categories = _.reduce(item.excludeCategories, (result, category) => {
        result.push(category.id)
        return result
      }, [])

      data.exclude_products = _.reduce(item.excludeProducts, (result, product) => {
        result.push(product.id)
        return result
      }, [])

      return data
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
