export default [
  {
    path: '/users',
    name: 'users-index',
    component: () => import('@/views/users/List.vue'),
    meta: {
      pageTitle: 'administration.navigation.users',
      breadcrumb: [
        {
          text: 'administration.navigation.users',
          to: { name: 'users-index' },
        },
        {
          text: 'administration.navigation.users_list',
          active: true,
        },
      ],
      resource: 'users',
      action: 'index',
    },
  },
  {
    path: '/users/create',
    name: 'users-store',
    component: () => import('@/views/users/Create.vue'),
    meta: {
      pageTitle: 'administration.navigation.users',
      breadcrumb: [
        {
          text: 'administration.navigation.users',
          to: { name: 'users-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'users',
      action: 'store',
    },
  },
  {
    path: '/users/edit/:id',
    name: 'users-update',
    component: () => import('@/views/users/Edit.vue'),
    meta: {
      pageTitle: 'administration.navigation.users',
      breadcrumb: [
        {
          text: 'administration.navigation.users',
          to: { name: 'users-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'users',
      action: 'update',
    },
  },
  {
    path: '/roles',
    name: 'roles-index',
    component: () => import('@/views/roles/List.vue'),
    meta: {
      pageTitle: 'administration.navigation.roles',
      breadcrumb: [
        {
          text: 'administration.navigation.users',
          to: { name: 'users-index' },
        },
        {
          text: 'administration.navigation.roles',
          active: true,
        },
      ],
      resource: 'roles',
      action: 'index',
    },
  },
  {
    path: '/roles/create',
    name: 'roles-store',
    component: () => import('@/views/roles/Create.vue'),
    meta: {
      pageTitle: 'administration.navigation.roles',
      breadcrumb: [
        {
          text: 'administration.navigation.users',
          to: { name: 'users-index' },
        },
        {
          text: 'administration.navigation.roles',
          to: { name: 'roles-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'roles',
      action: 'store',
    },
  },
  {
    path: '/roles/edit/:id',
    name: 'roles-update',
    component: () => import('@/views/roles/Edit.vue'),
    meta: {
      pageTitle: 'administration.navigation.roles',
      breadcrumb: [
        {
          text: 'administration.navigation.users',
          to: { name: 'users-index' },
        },
        {
          text: 'administration.navigation.roles',
          to: { name: 'roles-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'roles',
      action: 'update',
    },
  },
]
