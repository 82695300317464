<template>
  <section class="invoice-preview-wrapper">
    <b-row
      v-if="item"
      class="invoice-preview"
    >

      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <div>
                <h4 class="invoice-title">
                  <span class="invoice-number">#{{ item.id }}</span>
                </h4>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    {{ $t('general.created_at') }}:
                  </p>
                  <p class="invoice-date">
                    {{ item.created_at }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <hr class="invoice-spacing">

          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">{{ $t('administration.table.fields.status') }}: </span>
            <span>
              {{ $t(`general.statuses.${item.status}`) }}
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-1"
                block
                :style="`width: 200px;`"
                @click="changeStatus"
              >
                {{ $t('orders.change_status') }}
              </b-button>
            </span>
          </b-card-body>
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">{{ $t('administration.table.fields.type') }}: </span>
            <span>{{ $t(`reviews.types.${item.type}`) }}</span>
          </b-card-body>
          <b-card-body
            v-if="item.type === 'review'"
            class="invoice-padding pt-0"
          >
            <span class="font-weight-bold">{{ $t('administration.table.fields.rating') }}: </span>
            <span>{{ item.rating }}</span>
          </b-card-body>
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">{{ $t('administration.table.fields.name') }}: </span>
            <span>{{ item.name || item.user.name || '' }}</span>
          </b-card-body>
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">{{ $t('administration.table.fields.email') }}: </span>
            <span>{{ item.email || item.user.email || '' }}</span>
          </b-card-body>
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">{{ $t('administration.table.fields.reviewable') }}: </span>
            <span>{{ item.reviewable.title }}</span>
          </b-card-body>
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">{{ $t('administration.table.fields.description') }}: </span>
            <span>{{ item.description }}</span>
          </b-card-body>

          <hr class="invoice-spacing">
        </b-card>
      </b-col>

      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <b-form-group
            :label="$t('review.reply')"
            :label-for="'reply.review'"
          >
            <b-form-textarea
              :id="'reply.review'"
              v-model="reply"
            />
          </b-form-group>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="sendReply"
          >
            {{ $t('review.send_reply') }}
          </b-button>
        </b-card>
        <b-card>
          <b-card-body
            v-for="(child, index) in item.children"
            :key="index"
            class="invoice-padding pt-0"
          >
            <span class="font-weight-bold">{{ $t('reviews.reply_on_review') }}: </span>
            <span>{{ child.description }}</span>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import router from '@/router'
import {
  BRow, BCol, BCard, BCardBody, BButton,
  BFormGroup,
  BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import SweetAlertMixin from '@/mixins/SweetAlertMixin'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BFormGroup,
    BFormTextarea,
  },
  mixins: [SweetAlertMixin],
  data() {
    return {
      item: null,
      reply: null,
    }
  },
  beforeCreate() {
    this.$http.get(`/api/administration/reviews/${router.currentRoute.params.id}`)
      .then(response => {
        const { data } = response.data

        this.item = data
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.item = null
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'reviews-index' })
        }
      })
  },
  methods: {
    changeStatus() {
      this.confirm(result => {
        this.$http.put(`/api/administration/reviews/${router.currentRoute.params.id}/${result.value}`)
          .then(response => {
            const { data } = response.data
            this.item.status = data.status
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('notifications.successfully'),
                text: this.$t('notifications.success.updated'),
                icon: 'CoffeeIcon',
                variant: 'success',
              },
            })
          })
      }, {
        input: 'select',
        inputCustomClass: 'form-control',
        inputOptions: {
          approved: this.$t('general.statuses.approved'),
          pending: this.$t('general.statuses.pending'),
        },
        textarea: 'select',
        text: this.$t('notifications.confirm.update.text'),
      })
    },
    sendReply() {
      this.$http.post(`/api/administration/reviews/${router.currentRoute.params.id}/reply`, {
        description: this.reply,
      })
        .then(response => {
          const { data } = response.data
          this.item.reply = data
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              text: this.$t('notifications.success.updated'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
